import React from 'react'
import { useQuery } from 'react-query'
import api from 'services/api'
import SectionTitle from 'components/SectionTitle'
import SectionText from 'components/SectionText'
import { RodadasContainer, Rodada } from './styles'
import { Link } from 'react-router-dom'

type Response = {
  hash: string
  title: string
  month: number
  created_at: string
  updated_at: string
}[]

export function Rodadas() {
  const { data } = useQuery(['decomp-oficial', 'rodadas'], () => api.get<Response>('/model-results/decomp').then(({ data }) => data), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  return (
    <>
      <SectionTitle>Decomp - Oficial CCEE</SectionTitle>
      <SectionText>Escolha uma rodada para explorar os resultados.</SectionText>
      <RodadasContainer>
        {data?.map(rodada => (
          <Rodada key={rodada.hash}>
            <h1>{rodada.title}</h1>
            <p>{new Date(rodada.created_at).toLocaleString()}</p>
            <Link to={`/resultados-dos-modelos/decomp/${rodada.hash}`}>
              <button>Resultados</button>
            </Link>
          </Rodada>
        ))}
      </RodadasContainer>
    </>
  )
}
